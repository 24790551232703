import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 2,
  class: "columns"
}
const _hoisted_3 = { class: "descriptionColumn description" }
const _hoisted_4 = { class: "visualColumn" }
const _hoisted_5 = {
  key: 3,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.link)
      ? (_openBlock(), _createBlock("a", {
          key: 0,
          class: "title link",
          rel: "noopener noreferrer",
          target: "_blank",
          href: _ctx.link
        }, _toDisplayString(_ctx.title), 9, ["href"]))
      : (_openBlock(), _createBlock("div", {
          key: 1,
          class: "title",
          href: _ctx.link
        }, _toDisplayString(_ctx.title), 9, ["href"])),
    (_ctx.isTwoColumn)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "description")
          ]),
          _createVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "visual")
          ])
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "description")
        ]))
  ]))
}