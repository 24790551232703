<template>
    <div class="hairline"></div>
</template>

<style lang="scss">
@use "@/assets/global";

.hairline {
    height: 1px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 80vw;
    background-color: global.$subtle-color;
    @media (prefers-color-scheme: dark) {
        background-color: global.$dark-theme-subtle-color;
    }
}
</style>
