
import { defineComponent, computed } from "vue";

export default defineComponent({
    props: { title: String, link: String },
    setup(props, { slots }) {
        const isTwoColumn = computed(() => slots.visual !== undefined);

        return { isTwoColumn };
    },
});
